.first-container, .last-container{
    display: flex;
  }

.receiverEmail, .senderEmail{
  border-radius: 5px;
background: rgba(116, 106, 106, 0.50);
width: 340px;
height: 30px;
 margin: 5px;
 border: none;
 font-size: 18px;
}


.receiverEmail::placeholder, .senderEmail::placeholder{
  color:rgba(0, 0, 0, 0.50);
  padding: 10px;
  font-family: AppleSDGothicNeoSB00;
}

.reference{
  color:rgba(0, 0, 0, 0.50);
  width: 155px;
  height: 30px;
  border-radius: 5px;
background: rgba(116, 106, 106, 0.50);
font-family: AppleSDGothicNeoSB00;
font-size: 18px;
padding-top: 3px;
padding-left: 10px;
margin-top: 5px;
}

.emailContents{
  width: 514px;
  height: 600px;
  border-radius: 5px;
  border: 3.25px solid rgba(116, 106, 106, 0.50);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.20);
  overflow-y: scroll;
}

.one, .two, .three{
  border: 3px solid rgba(116, 106, 106, 0.50);
  border-radius: 5px;
  margin: 20px 10px;
  padding: 15px;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infoKind{
  margin-left: 20px;
  margin-top: 30px;
  font-family: AppleSDGothicNeoSB00;
font-size: 18px;
font-style: normal;
font-weight: 700;
}

.copy{
  width: 26px;
  height: 26px;
  background-color: white;
  border: none;
  align-self: flex-end;
  margin-top: auto;
}

.right-container{
  visibility: visible;
}

.Loading{
  visibility: hidden;
}

.SecondemailContents{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 514px;
  height: 600px;
  border-radius: 5px;
  border: 3.25px solid rgba(116, 106, 106, 0.50);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.20);
  background: rgb(51,51,51);
  /* opacity: 0.5; */
}

.loadingBackground{
  position: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loadingIcon{
  width:60px;
  height: 50px;
  /* margin-top: 2px; */
}

.infoLoading{
  color: white;
  z-index: 10;
  margin: 30px;
  font-family: AppleSDGothicNeoSB00;
font-size: 18px;
font-weight: 600;
}
/* .Infos{
  margin-right: 100px;

} */

/* .titleInfo{
  margin-top: 20px;
}

.greetInfo{
  margin-top: 40px;
}

.mainInfo{
  margin-top: 60px;
} */
