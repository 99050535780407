.footer{
    position: fixed;
    left:0;
    bottom:0;
    width:100%;
    height: 80px;
    background: #2D2830;
    display: flex;
    justify-content: space-between;

}

.leftment-1, .leftment-2, .rightment{
color:#FFF;
font-family: AppleSDGothicNeoSB00;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.leftcontainer{
    display: flex;
    flex-direction: column;
    margin-left: 167px;
}


.rightment{
margin-right: 163px;
text-align: right;

}