.backgroundImage{
    background-image: url('../../asset/background.png');
    background-size: cover;
}

.intro {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-left: 450px;
  padding-top: 80px;
  gap: 1px;
}

.intro1{
    color: #000;
    font-family: AppleSDGothicNeoSB00;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
/* text-align: center; */ 
}

.intro2{
    color: #000;
font-family: AppleSDGothicNeoSB00;
font-size: 21px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 10px;
}

.introImage{
  width: 550px;
  height: 120px;
  flex-shrink: 0;
  box-sizing: border-box;
}

.buttons{
  box-sizing: border-box;
  display: flex;
  gap: 59px;
}

.button{
  width: 242px;
  height: 70px;
  padding: 13px 20px;
  color: #000;
  text-align: center;
  font-family: AppleSDGothicNeoSB00;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border:none;
  border-radius: 100px;
background: var(--White, #FFF);
box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.20), 1px 1px 11px 0px rgba(0, 0, 0, 0.15) inset;
}


.imageWrapper {
    position: relative;
    /* width: 50px; 
    height: 50px;   */
  }

.monkey {
    position: absolute;
    bottom: 0;
    /* left: 40px; */
    width: 500px;
    height: 500px;
    z-index: 2; /* backMonkey보다 앞에 오도록 설정 */
    pointer-events: 'none',
  }

  .backMonkey {
    position: absolute;
    width: 1013px;
    height: 255px;
    bottom: 0;
    left: 0;
    z-index: 1; /* monkey보다 뒤에 오도록 설정 */
    pointer-events: 'none',
  }

  .right-logo{ 
    position: absolute;
    width: 530px;
    height: 360px;
    top: 0;
    right: 0;
    z-index: 1; /* monkey보다 뒤에 오도록 설정 */
  }

  .container {
    display: flex;
    justify-content: space-between; /* 두 요소를 양 끝으로 정렬 */
    align-items: center; /* 세로 축에서 가운데 정렬, 필요시 사용 */
  }
  
.blank{
  width: 140px;
  height: 500px;
  margin-right: 30px;
}