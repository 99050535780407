.header{
  display: flex;
  justify-content: space-between; /* logo와 메뉴 컨테이너를 양 끝으로 정렬 */
  align-items: center; /* 세로 중앙 정렬 */

  padding: 26px 40px 0px;

  margin: 0 auto;
  
  background-color: #FFD875;
  max-width: auto;
  min-width: 1280px;
  
}

.menu-container{
  display:flex;
  gap: 28px;
  align-items: center;
}

.menu-item{
  text-decoration: none;
  font-family: AppleSDGothicNeoSB00;
  font-style: normal;
  font-size: 18px;
  color: black;
  font-weight: 400;
  line-height: normal;
}


.userlogo{
    width: 33px;
    height: 33px;
    border-radius: 50px;
}
