h2{
    display: flex;
    justify-content: center;

}
.yellow{
    margin-top: 25px;
}
.name-input{
color: white;
display: flex;
width: 168px;
height: 28px;
padding: 0px 10px 0px 15px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
border-radius: 200px;
border: none;
background-color: #746A6A;
   
}

.situation-input{
    display: flex;
width: 389px;
height: 28px;
padding: 0px 10px 0px 15px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
border-radius: 200px;
background: var(--sub2, #746A6A);
border: none;
color: white;
margin: 20px 20px;
margin-left:35px;
}

.name-input::placeholder, .situation-input::placeholder{
    color:white;
}

.Q{
    display: flex;
    justify-content: space-between;
    margin: 15px 20px;
}

.question{
    margin: 5px 20px;
}

.info{
    color: black;
text-align: center;
font-family: AppleSDGothicNeoSB00;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 60px;
}

hr{
    height: 1.3px;
    border: 0;
    background-color: #FFD875;
    width: 430px;
}